<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0" v-if="success_status=='Loading'">

        <!-- logo -->
        <b-link class="brand-logo">
          <logo-dark class="w-50 h-25"/>

          <!--          <h2 class="brand-text text-primary ml-1">-->
          <!--            CloudX-->
          <!--          </h2>-->
        </b-link>
        <b-card-title class="mb-1">
         Validating Your Token 🖐🏻
        </b-card-title>
        <b-card-text class="mb-2">
          Please Wait!
        </b-card-text>
        <div class="text-center">
          <b-spinner
              style="width: 3rem; height: 3rem;"
              class="mr-1"
              variant="primary"
              label="Large Spinner"
          />
        </div>
        <!-- form -->

        <p class="text-center mt-2">
          <b-link :to="{name:'auth-login-v1'}">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </p>

      </b-card>
      <b-card class="mb-0" v-else-if="success_status=='Invalid'">

        <!-- logo -->
        <b-link class="brand-logo">
          <logo-dark class="w-50 h-25"/>

          <!--          <h2 class="brand-text text-primary ml-1">-->
          <!--            CloudX-->
          <!--          </h2>-->
        </b-link>
        <b-card-title class="mb-1">
          Token Invalid 💥
        </b-card-title>
        <b-card-text class="mb-2">
          Your token is invalid! Please restart your process!
        </b-card-text>

        <!-- form -->

        <p class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </p>

      </b-card>
      <!-- Reset Password v1 -->
      <b-card class="mb-0" v-else>

        <!-- logo -->
        <b-link class="brand-logo">
          <logo-dark class="w-50 h-25"/>

          <!--          <h2 class="brand-text text-primary ml-1">-->
          <!--            CloudX-->
          <!--          </h2>-->
        </b-link>
        <b-card-title class="mb-1">
          Reset Password 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Your new password must be different from previously used passwords
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >

            <!-- password -->
            <b-form-group
              label="New Password"
              label-for="reset-password-new"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Confirm Password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
            >
              Set New Password
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </p>

      </b-card>
    <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton,BSpinner
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LogoDark from "@core/layouts/components/logo-dark";

export default {
  components: {
    LogoDark,
    VuexyLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
      success_status:"Loading",
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  beforeCreate() {
    this.$store.dispatch('forgotPassword/validateResetToken',this.$route.params).then(result=>{
      if(result.status==='OK'){
        this.success_status='valid'
      }else{
        this.success_status='Invalid'
      }
    })
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let payload={
            "token": this.$route.params.token,
            "password": this.password
          }
          this.$store.dispatch('forgotPassword/updatePassword',payload).then(result=>{
            console.log(result)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: result.status!=='OK'?'Error' :`Success`,
                icon:result.status!=='OK'?'ThumbsDownIcon':'ThumbsUpIcon',
                variant: result.status!=='OK'?'danger':'success',
                text:  result.status!=='OK'?'Password too common. Change it a bit!': 'Password Updated Successfully!',
              },
            })
            if(result.status=='OK'){
              this.$router.push({name:'auth-login'})
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
